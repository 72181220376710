import '../../../App.css'
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Table, Modal, Button, Checkbox, Form, Input, Select, Pagination } from 'antd';
import qs from 'qs';
import axios from 'axios';
import moment from "moment";
import Appconfig from '../../../config/config'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import WhatsAppIcon from '../../whatsapp-icon';

function PaymentSetup() {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [exposerLimitForm] = Form.useForm();
    const [changeStatusModal, setChangeStatusModal] = useState(false)
    const [editExposureLimitModal, setEditExposureLimitModal] = useState(false)
    const [addPayment, setAddPayment] = useState(false);

    const handleAddPayment = () => {
        setAddPayment(true);
        navigate('/add-payment-method');
    }

    const [bankingModal, setbankingModal] = useState(false)

    const [button, setButton] = useState(false)
    const [loading, setLoading] = useState(true);



    const [balance, setBalance] = useState(0)
    let locale = {
        emptyText: 'No data!',
    };
    let totalBalance = 0;
    let totalExposure = 0;


    function updateTotalBalance(bal) {
        // console.log('bal', bal);
        totalBalance = parseFloat(bal);
        if (document.querySelector("#totalBalance")) {
            document.querySelector("#totalBalance").textContent = `IRP ${totalBalance.toFixed(0)}`;
        }

    }
    function updateTotalExposure(bal) {
        totalExposure = parseFloat(bal);

        if (document.querySelector("#totalExposure")) {
            document.querySelector("#totalExposure").innerHTML = `${totalExposure < 0 ? `<span className="text-[#d50000]">(${parseFloat(Math.abs(totalExposure)).toFixed(0)})</span>` : '( ' + totalExposure.toFixed(0) + ' )'}`;
        }
        if (document.querySelector("#totalAvailBal")) {
            document.querySelector("#totalAvailBal").textContent = `IRP ${((parseFloat(totalBalance) - Math.abs(totalExposure))).toFixed(0)}`;
        }
        if (document.querySelector("#mastersAvailBal")) {
            let myBal = document.getElementById('mastersBalance').textContent
            if (Number(myBal)) {
                myBal = myBal.split(' ')[1];
            } else {
                myBal = 0;
            }

            // if (balance === 0) {
            //     document.querySelector("#mastersAvailBal").textContent = "Loading ..."
            //     // document.querySelector("#mastersAvailBalPl").textContent = "Loading ..."

            // }
            // else {
            document.querySelector("#mastersAvailBal").textContent = `IRP ${((parseFloat(myBal) + parseFloat(totalBalance) - Math.abs(totalExposure))).toFixed(0)}`;
            // document.querySelector("#mastersAvailBalPl").textContent = `IRP ${((parseFloat(balance) + parseFloat(totalBalance) - Math.abs(totalExposure))).toFixed(0)}`;

            // }
        }
    }

    const columns = [
        {
            title: 'S.no',
            // dataIndex: 'user_name',
            sorter: true,
            
        },
        {
            title: 'Type',
            // dataIndex: 'credit_reference',
            sorter: true,

        },

        {
            title: 'Vendor',
            sorter: false,
        },
        {
            title: 'UPI ID / Account Number',
            // dataIndex: 'exposure',
            sorter: true,
            
        },
        {
            title: 'Account Holders Name',
            // dataIndex: 'exposer_limit',
            sorter: true,

        },
        {
            title: 'IFSC Code',
            // dataIndex: 'name',
            sorter: false,
        },
        {
            title: 'Status',
            // dataIndex: 'name',
            sorter: true,
            render: (_, record) => {
                if (record.status == 'active') {
                    return (
                        <span className='text-[0.688rem] font-extrabold px-[5px] py-[3px] rounded-[3px] bg-[#e5f1dc] border-[1px] border-[solid] border-[#bedca7] text-[#508d0e] leading-none' >{record.status}</span>
                    )
                }
                if (record.status == 'locked') {
                    return (
                        <span className='text-[0.688rem] font-extrabold px-[5px] py-[3px] rounded-[3px] bg-[#e3e8eb] border-[1px] border-[solid] border-[#b9c5cd] text-[#5a7384] leading-none' >{record.status}</span>
                    )
                }
                if (record.status == 'suspended') {
                    return (
                        <span className='text-[0.688rem] font-extrabold px-[5px] py-[3px] rounded-[3px] bg-[#f2e2e6] border-[1px] border-[solid] border-[#deb6c0] text-[#d0021b] leading-none' >{record.status}</span>
                    )
                }
            }
        },
        {
            title: 'Actions',
            // dataIndex: 'name',
            sorter: true,
            render: (_, record) => (
                <ul className='flex float-left p-0 m-0'>
                    
                    <li className='block float-left'>
                        <button className='w-[26px] h-[26px] ml-[12px] text-[0] flex bg-[#f3f3f3] rounded-[6px] border-[1px] border-[solid] border-[#bbbbbb] float-right cursor-pointer items-center justify-center' disabled={button} onClick={() => deleteUser(record._id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 26 26" enableBackground="new 0 0 26 26" xmlSpace="preserve">
                                <path fill="currentColor" d="M16.162,0.213H9.838C9.358,0.535,9.005,1.049,8.78,1.821H2.233c-0.191,0-0.32,0.097-0.32,0.29v2.541    c0,0.193,0.129,0.29,0.32,0.322h21.534c0.224-0.032,0.32-0.129,0.32-0.322V2.111c0-0.193-0.097-0.29-0.32-0.29h-6.514    C16.995,1.049,16.643,0.535,16.162,0.213z"></path>
                                <path fill="currentColor" d="M19.725,25.788c1.088-0.453,1.698-1.256,1.795-2.415c0-0.031,0-0.062,0-0.097l1.058-16.694H3.454    l1.027,16.694c0,0.035,0,0.065,0.031,0.097c0.096,1.159,0.674,1.962,1.765,2.415H19.725z"></path>
                            </svg>
                        </button>
                    </li >
                </ul >
            )
        },
    ];
   
    

    const [openAddUser, setOpenAddUser] = useState(false)
    const [isCommission, setIsCommission] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const userInfo = JSON.parse(localStorage.getItem('userdata'))
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [currentCreditRef, setCurrentCreditRef] = useState(0)
    const [currentExposureLimit, setCurrentExposureLimit] = useState(0)
    const [creditRef, setCreditRef] = useState(0)
    const params = useParams();
    const [submitClick, setSubmitClick] = useState(false)
    const [uplinePL, setUplinePL] = useState(0);
    const [uplineUserData, setUplineUserData] = useState();

    const [formData, setFormData] = useState({
        master_id: params.user_id ? params.user_id : userInfo?._id,
        user_type: 'User',
        registration_date: moment().format("DD-MMM-YYYY"),
    });

    const [userRefData, setUserRefData] = useState({
        user_id: "",
        credit_reference: "",
        password: "",
        user_name: ""
    });
    const [userBankingData, setUserBankingData] = useState({
        master_id: "",
        password: "",
        user_id: "",
        amount: "",
        action: "",
        remark: "",
        user_name: ""
    });
    const [userStatusData, setUserStatusData] = useState({
        user_id: "",
        status: "",
        password: "",
        user_name: "",
        change_status: true,
    });

  
    const handleSearch = (value, dataIndex) => {

        if (!value) {
            setFilteredData(data);
            return;
        }

        const filtered = data.filter(item => {
            return Array.isArray(dataIndex)
                ? dataIndex.some(key => item[key]?.toString().toLowerCase().includes(value.toLowerCase()))
                : item[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase());
        });

        // const filtered = data.length ? data.filter(item => item[dataIndex].toString().toLowerCase().includes(value.toLowerCase())) : [];
        setFilteredData(filtered);
        setCurrent(1); // Reset to the first page after a search
    };

    const handlePageSizeChange = (e) => {
        setPageSize(e);
        setCurrent(1); // Reset to the first page after changing page size
    };

    const handleFirst = () => setCurrent(1);
    const handleLast = () => setCurrent(Math.ceil(filteredData?.length / pageSize));

    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = filteredData?.length > 0 && filteredData.slice(startIndex, endIndex);




    const getUsersDataCalc = () => {
        var data = {
            master_id: userInfo?._id,
            // user_type: 'user'
            user_type: userInfo?.user_type
        };
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getAuraUsers`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                if (response.data) {

                    let userData = response.data;
                    let totalBal = 0;
                    let totalExp = 0;

                    userData?.map(data => {
                        totalBal += Number(data.noDeductbalance);
                        totalExp += Number(data.exposure);
                    })
                    updateTotalBalance(totalBal)
                    updateTotalExposure(totalExp)
                }
                else {
                    console.log(response)
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const getUsersData = () => {
        var data = {
            master_id: params.user_id ? params.user_id : userInfo?._id,
            user_type: 'user'
        };
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getAuraUsers`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                if (response.data) {
                    setFilteredData(response.data)
                    setData(response.data)
                    setLoading(false);

                    // let userData = response.data;
                    // let totalBal = 0;
                    // let totalExp = 0;

                    // userData?.map(data => {
                    //     totalBal += Number(data.noDeductbalance);
                    //     totalExp += Number(data.exposure);
                    // })
                    // updateTotalBalance(totalBal)
                    // updateTotalExposure(totalExp)
                }
                else {
                    console.log(response)
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const onFinish = async (values) => {
      Object.assign(values, { rolling_commission: isCommission === true ? 'Yes' : 'No' })
      Object.assign(formData, values)

      if (!isCommission) {
          formData.rolling_commission_binary = '';
          formData.rolling_commission_bookmaker = '';
          formData.rolling_commission_casino = '';
          formData.rolling_commission_fancy = '';
          formData.rolling_commission_matka = '';
          formData.rolling_commission_sportbook = '';
          formData.rolling_commission_virtualsport = '';
      } else {
          formData.commission = '';
      }

      formData.registration_date = moment(formData.registration_date).format(
          "YYYY-MM-DD"
      );
      // formData.user_type = params.user_type ? currentname(params.user_type) : getNextUserType();
      formData.master_id = params.user_id ? params.user_id : userInfo._id;
      // return false;
      setIsDisabled(true);
      var data = JSON.stringify(formData);
      var config = {
          method: "post",
          url: `${Appconfig.apiUrl}users/addAuraUser`,
          headers: {
              "Content-Type": "application/json",
          },
          data: data,
      };
      axios(config)
          .then(function (response) {
              if (response.data.result) {
                  setOpenAddUser(false)
                  getUsersData()
                  form.resetFields();
                  NotificationManager.success(`${response.data.resultMessage}`, '', 3000);
              }
              else {
                  NotificationManager.error(`${response.data.resultMessage}`, '', 3000);
              }
              setIsDisabled(false);

          })
          .catch(function (error) {
              console.log(error);
          }).finally(function () {
              form.resetFields();
          })
      setTimeout(function () {
          // closeMessage();
      }, 3000);


  };

  const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
  };

    

    useEffect(() => {
        getUsersData();
        getUsersDataCalc();
        getUplineUserData();
    }, [params])

    const changeStatus = (open, record) => {
        setChangeStatusModal(open)
        // setCurrentCreditRef(record.credit_reference)
        setUserStatusData({
            ...userStatusData,
            user_id: record._id,
            user_name: record.user_name,
            status: record.status,
            record
        })
    }
    const editExposureLimit = (open, record) => {
        setEditExposureLimitModal(open)
        setCurrentExposureLimit(record.exposer_limit)
        setUserRefData({
            ...userRefData,
            user_id: record._id,
            user_name: record.user_name
        })
    }

    

    const handleSubmitPayment = async (action) => {
        var data = JSON.stringify({
            master_id: userBankingData.master_id,
            password: userBankingData.password,
            userArray: [
                {
                    user_id: userBankingData.user_id,
                    amount: userBankingData.amount,
                    action: action,
                    remark: userBankingData.remark
                }
            ]
        });
        if (userBankingData.password == uplineUserData?.password ? uplineUserData?.password : userInfo.password) {
            setSubmitClick(true);

            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}ledger/auraSaveBankingInfo`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    if (response.data.result) {
                        console.log(response)
                        setbankingModal(false)
                        setUserBankingData({
                            master_id: "",
                            password: "",
                            user_id: "",
                            amount: "",
                            action: "",
                            remark: "",
                            user_name: ""
                        })
                        getUsersData()
                        getBalance()
                        NotificationManager.success(response.data.message, '', 3000);
                    }
                    else {
                        NotificationManager.error(response.data.message, '', 3000);
                    }
                    setSubmitClick(false);

                })
                .catch(function (error) {
                    console.log(error);
                    // NotificationManager.success(response.data.message, '', 3000);
                });
        }
        else {
            NotificationManager.error('Your Password Not Matched With System.', '', 3000);
        }
    }
    function getBalance() {
        var data = JSON.stringify({
            user_id: userInfo?._id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getUserBalance`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result) {
                    setBalance(response.data.resultData.balance)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getBalance();
        // countUplinePl();
    }, [])

    const getUplineUserData = () => {
        var data = {
            user_id: params.user_id ? params.user_id : userInfo?._id,
        };

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getAuraUserByUserId`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {

                if (response.data.result) {
                    setUplineUserData(response.data)
                    let uplineData = response.data.resultData;
                    let pll = 0;
                    let pl = Number(response.data.noDeductbalance) - Number(uplineData.credit_reference);
                    if (pl) {
                        pll = Number(pl);
                    }
                    setUplinePL(pll);
                }
                else {
                    console.log(response)
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const deleteUser = async (values) => {
        var data = JSON.stringify({
            "user_id": values,

        });
        setButton(true);
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}users/deleteAuraUser`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                if (response.data.result != '0') {
                    NotificationManager.success(response.data.resultMessage, '', 3000);
                    // setEditExposureLimitModal(false)
                    getUsersData()
                    // exposerLimitForm.resetFields();
                }
                else {
                    NotificationManager.error(`${response.data.resultMessage}`, '', 3000);
                }
                setButton(false);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <NotificationContainer />
            <div className='grid grid-cols-12 relative  px-[15px] lg:px-[30px] my-[28px]  lg:px-[0px] lg:top-[0px]' >
            <div className='col-span-12   mx-[0]'>
                    <button className='flex items-center justify-center h-[30px] w-[30px] ml-[10px] mr-[5px] my-[0] bg-[#eee] border-[1px] border-[solid] border-[#bbb] rounded-[4px] text-[#1e1e1e] font-bold leading-[23px] text-[0.75rem] block text-center float-right'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#black" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 4C9 4 6.4 5.6 5.1 8.1L4 7V11H8L6.5 9.5C7.5 7.5 9.6 6 12 6C15.3 6 18 8.7 18 12C18 15.3 15.3 18 12 18C10.2 18 8.6 17.2 7.5 15.9L6 17.2C7.4 18.9 9.6 20 12 20C16.4 20 20 16.4 20 12C20 7.6 16.4 4 12 4Z" />
                        </svg>
                    </button>

                    <button className='flex items-center gap-[5px] h-[30px] m-0 leading-[30px] px-[15px] py-[0] bg-[#eee] border-[1px] border-[solid] border-[#bbb] rounded-[4px] !text-[#1e1e1e] font-bold text-[.75rem] block float-right no-underline cursor-pointer' onClick={handleAddPayment}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 6C11.3 6 10 7.3 10 9C10 10.7 11.3 12 13 12C14.7 12 16 10.7 16 9C16 7.3 14.7 6 13 6ZM19.5 6C18.1193 6 17 7.11929 17 8.5C17 9.88071 18.1193 11 19.5 11C20.8807 11 22 9.88071 22 8.5C22 7.11929 20.8807 6 19.5 6ZM4 8V11H1V13H4V16H6V13H9V11H6V8H4ZM19.5 13C18.3 13 17.3875 13.3125 16.6875 13.8125C18.9875 14.9125 19.9062 16.8 19.9062 17V17.0938H24V15.8125C24 15.7125 22.9 13 19.5 13ZM13 14C8.4 14 7 17.3125 7 17.3125V19H19V17.3125C19 17.3125 17.6 14 13 14Z" fill="black" />
                        </svg>
                        Add New
                    </button>
                </div>

                <div className='col-span-12 '>
                <div className='bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] font-bold text-[0.938rem] px-[10px] py-[5px] [word-wrap:break-word]'>
                            <span className='text-[#ffffff] font-bold text-[0.938rem]  py-[5px] [word-wrap:break-word]'>Payment Setup</span>
                        </div>

                    <div className='bg-[#fff] border-[1px] border-[#0000002d] '>
                        <div className='p-[1.25rem] flex-auto '>
                            <Table
                                title={() => (
                                    <div className='grid grid-cols-12'>
                                        <div className='col-span-12 lg:col-span-6 flex items-center justify-center lg:justify-start mb-[.8rem] lg:mb-[0px]'>
                                            <div className='text-[#333]'>
                                                <label>
                                                    Show
                                                    <Select defaultValue={10} onChange={handlePageSizeChange} className='pagesize-select mx-[5px]'>
                                                        <Select.Option value={10}>10</Select.Option>
                                                        <Select.Option value={25}>25</Select.Option>
                                                        <Select.Option value={50}>50</Select.Option>
                                                        <Select.Option value={100}>100</Select.Option>
                                                    </Select>
                                                    entries
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-span-12 lg:col-span-6 flex items-center justify-center lg:justify-end'>
                                            <div className='text-[#333]'>
                                                <label>
                                                    Search:
                                                    <Input
                                                        onChange={e => handleSearch(e.target.value, ['name', 'user_name'])}
                                                        style={{ width: '150px', marginLeft: '5px' }}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                locale={locale}
                                columns={columns}
                                className='downline-list'
                                bordered
                                loading={loading}
                                // dataSource={paginatedData}
                                dataSource={paginatedData}
                                pagination={false}
                            />
                            <div className='grid grid-cols-12 items-center pt-[1.5rem] lg:pt-[.75rem] text-[#333]'>
                                <div className='col-span-12 lg:col-span-6 text-[#333] text-[0.813rem]'>
                                    Showing {startIndex + 1} to {endIndex} of {filteredData?.length} entries
                                </div>
                                <div className='col-span-12 lg:col-span-6 flex items-center justify-end  pt-[1rem] lg:pt-[0px] lg:mt-[0rem]'>
                                    <button className='text-[0.813rem] cursor-default text-[#666] border-[1px] border-[solid] border-[transparent] bg-transparent [box-shadow:none] box-border inline-block min-w-[1.5em] px-[1em] py-[.5em] ml-[2px] text-center no-underline rounded-[2px] cursor-pointer' type='button' onClick={handleFirst} style={{ marginRight: 8 }}>First</button>
                                    <Pagination
                                        current={current}
                                        pageSize={pageSize}
                                        total={filteredData?.length}
                                        onChange={(page) => setCurrent(page)}
                                        itemRender={(page, type, originalElement) => {
                                            if (type === 'prev') {
                                                return <button type='button' className='!cursor-pointer'>Previous</button>;
                                            }
                                            if (type === 'page') {
                                                return null; // Hide page numbers
                                            }
                                            if (type === 'next') {
                                                return <button type='button' className='!cursor-pointer'>Next</button>;
                                            }
                                            return originalElement;
                                        }}
                                        showSizeChanger={false} // Disable default page size changer
                                    />
                                    <button className='text-[0.813rem] cursor-default text-[#666] border-[1px] border-[solid] border-[transparent] bg-transparent [box-shadow:none] box-border inline-block min-w-[1.5em] px-[1em] py-[.5em] ml-[2px] text-center no-underline rounded-[2px]  cursor-pointer' type='button' onClick={handleLast} style={{ marginLeft: 8 }}>Last</button>
                                </div>
                            </div>
                            {/* <Table columns={columns} dataSource={data} /> */}
                        </div>
                    </div>
                </div>
            </div >
            <WhatsAppIcon />
        </>
    );
}

export default PaymentSetup;
