import { useState } from "react";
import "../../App.css";
// import WhatsAppIcon from "../whatsapp-icon"; 

const Index = () => {

  return (
    <div>
      <div className="grid grid-cols-12 relative px-[15px] lg:px-[30px] my-[28px] lg:px-[0px] lg:top-[0px]">
        <div className="col-span-12">
          <div className="bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] font-bold text-[0.938rem] px-[10px] py-[5px] [word-wrap:break-word]">
            <span className="text-[#ffffff] font-bold text-[0.938rem] py-[5px] [word-wrap:break-word]">
              Add Banner
            </span>
          </div>
          <div className="bg-[#fff] border-[1px] border-[#0000002d] p-3"> {/* Added padding for spacing */}
            <span className="block m-[10px] p-[10px]">
              Upload Header Banner
            </span>
            <div className="col-span-12 flex items-center justify-between"> {/* Use flexbox for alignment */}
              <div className="ml-[15px] mr-[2px] my-[2px] p-[2px]">
                <input type="file" name="" id="" />
              </div>
              <button className="flex items-center gap-[5px] h-[30px] leading-[30px] px-[15px] py-[0] bg-[#fff] border-[1px] border-[solid] border-[#000] rounded-[4px] !text-[#1e1e1e] font-bold text-[.75rem] no-underline cursor-pointer">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
