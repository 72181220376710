import React from 'react';

const index = () => {
  return (
    <div>
      Mobile Number
    </div>
  );
}

export default index;
