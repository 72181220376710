import { useState } from "react";
import "../../App.css";
import WhatsAppIcon from "../whatsapp-icon"; 

const Index = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="grid grid-cols-12 relative  px-[15px] lg:px-[30px] my-[28px]  lg:px-[0px] lg:top-[0px]">
        <div className="col-span-12 ">
          <div className="bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] font-bold text-[0.938rem] px-[10px] py-[5px] [word-wrap:break-word]">
            <span className="text-[#ffffff] font-bold text-[0.938rem]  py-[5px] [word-wrap:break-word]">
              Payment Method
            </span>
          </div>
          <div className="bg-[#fff] border-[1px] border-[#0000002d] ">
            <span className="block m-[10px] p-[10px]">Payment Type</span>
            <div className="col-span-12">
              <div className="relative inline-block text-left m-2">
                <div>
                  <button
                    type="button"
                    onClick={toggleDropdown}
                    className="inline-flex justify-center w-full px-4 py-2 bg-white-600 text-black font-medium text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-black-500 border-[1px] border-[solid]"
                  >
                    --Select Type--
                    <svg
                      className="-mr-1 ml-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3a1 1 0 00-.7 1.7l4 4a1 1 0 001.4 0l4-4A1 1 0 1017.3 3l-3.3 3.3L10 3z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                {isOpen && (
                  <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        UPI
                      </a>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Bank
                      </a>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Phonepe
                      </a>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        G-Pay
                      </a>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Paytm
                      </a>
                    </div>
                  </div>
                )}
              </div>
            <button className="flex items-center gap-[5px] h-[30px] m-3 leading-[30px] px-[15px] py-[0] bg-[#fff] border-[1px] border-[solid] border-[#000] rounded-[4px] !text-[#1e1e1e] font-bold text-[.75rem] block float-right no-underline cursor-pointer">
              Save
            </button>
            </div>
          </div>
        </div>
      </div>
      <WhatsAppIcon/>
    </div>
  );
};

export default Index;
