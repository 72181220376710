import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from './whatsapp-icon.json';

const WhatsAppIcon = () => {
  return (
    <div className="fixed bottom-4 right-4"> {/* Fixed position at the bottom right corner */}
      <a
        href="https://wa.me/9682001234" // Replace with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-icon"
      >
        <Player
          autoplay
          loop
          src={animationData} // Your Lottie animation JSON file
          style={{ height: '50px', width: '50px' }} // Adjust size as needed
        />
      </a>
    </div>
  );
};

export default WhatsAppIcon;
