import "../../../App.css";
import { useState, useEffect } from "react";
import {
  Modal,
  Input,
  DatePicker,
  Table,
  Select,
  Pagination,
  Button,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import qs from "qs";
import dayjs from "dayjs";
import moment from "moment";
import axios from "axios";
import Appconfig from "../../../config/config";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
// import WhatsAppIcon from '../whatsapp-icon';

function HeaderBanner() {
  const dateFormat = "DD/MM/YYYY";
  const userInfo = JSON.parse(localStorage.getItem("userdata"));
  const params = useParams();
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [button, setButton] = useState(false);

  const [addBanner, setAddBanner] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [type, setType] = useState("unsettle");
  const [formData, setFormData] = useState({
    user_id: userInfo && userInfo?._id,
    event_type: 4,
    from_date: moment().subtract(1, "days").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    status: "Open",
  });

  // ***************  Calendar Start ****************

  const [showFromCalendar, setShowFromCalendar] = useState(false);

  const handleFromDate = (newDate) => {
    if (newDate) {
      setFormData({
        ...formData,
        from_date: newDate,
      });
      setShowFromCalendar(false);
    }
    // Hide the calendar after selecting a date
  };

  const toggleFromCalendar = () => {
    setShowFromCalendar(!showFromCalendar);
    setShowToCalendar(false);
  };

  const [showToCalendar, setShowToCalendar] = useState(false);

  const handleToDate = (newDate) => {
    if (newDate) {
      setFormData({
        ...formData,
        to_date: newDate,
      });
      //   setToDate(newDate);
      setShowToCalendar(false); // Hide the calendar after selecting a date
    }
  };

  const toggleToCalendar = () => {
    setShowToCalendar(!showToCalendar);
    setShowFromCalendar(false);
  };
  const formattedFromDate = formData.from_date
    ? format(formData.from_date, "dd-MM-yyyy")
    : format(new Date(), "dd-MM-yyyy");
  const formattedToDate = formData.to_date
    ? format(formData.to_date, "dd-MM-yyyy")
    : format(new Date(), "dd-MM-yyyy");
  console.log("Formatted Date:", formattedFromDate); // Debug: log the formatted date

  // ***************  Calendar End ****************

  const handleAddBanner = () =>{
    setAddBanner(true);
    navigate('/add-header-banner')
  }

  const [userStatusData, setUserStatusData] = useState({
    user_id: "",
    status: "",
    password: "",
    user_name: "",
    change_status: true,
  });

  const changeStatus = (open, record) => {
    setChangeStatusModal(open);
    // setCurrentCreditRef(record.credit_reference)
    // console.log('record:', record)
    setUserStatusData({
      ...userStatusData,
      user_id: record._id,
      user_name: record.user_name,
      status: record.status,
      record,
    });
  };

  const deleteUser = async (values) => {
    var data = JSON.stringify({
      user_id: values,
    });
    setButton(true);
    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}users/deleteAuraUser`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        if (response.data.result != "0") {
          NotificationManager.success(response.data.resultMessage, "", 3000);
          // setEditExposureLimitModal(false)
          // getUsersData()
          // exposerLimitForm.resetFields();
        } else {
          console.log(response);
          NotificationManager.error(`${response.data.resultMessage}`, "", 3000);
        }
        setButton(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const getUsersData = () => {
  //     var data = {
  //         master_id: params.user_id ? params.user_id : userInfo._id,
  //         user_type: 'user'
  //     };
  //     var config = {
  //         method: "post",
  //         url: `${Appconfig.apiUrl}users/getAuraUsers`,
  //         headers: {
  //             "Content-Type": "application/json",
  //         },
  //         data: data,
  //     };
  //     axios(config)
  //         .then(function (response) {
  //             if (response.data) {
  //                 setFilteredData(response.data)
  //                 setData(response.data)
  //                 setLoading(false);
  //                 console.log('response.data', response.data)
  //                 let userData = response.data;
  //                 let pll = 0;
  //                 userData?.map(data => {
  //                     let pl = Number(data.noDeductbalance) - Number(data.credit_reference);
  //                     if (pl) {
  //                         pll += Number(pl);
  //                     }
  //                 })
  //                 setDownlinePl(pll);
  //             }
  //             else {
  //                 console.log(response)
  //             }

  //         })
  //         .catch(function (error) {
  //             console.log(error);
  //         });

  // }

  const onChangeFromDate = (startdate) => {
    setFormData({
      ...formData,
      from_date: startdate,
    });
  };
  const onChangeToDate = (enddate) => {
    setFormData({
      ...formData,
      to_date: enddate,
    });
  };

  const onChangeBetStatus = (betstatus) => {
    setFormData({
      ...formData,
      status: betstatus,
    });
  };
  const onChangeSportType = (sporttype) => {
    setFormData({
      ...formData,
      event_type: sporttype,
    });
  };
  let locale = {
    emptyText: "No data!",
  };
  const columns = [
    {
      title: "S.No",
      sorter: true,
    },
    {
      title: "Header Preview",
      sorter: true,
    },
    {
      title: "Status",
      sorter: true,
    },
    {
      title: "Actions",
      // dataIndex: 'name',
      sorter: true,
      render: (_, record) => (
        <ul className="flex float-left p-0 m-0">
          <li className="block float-left">
            <Link
              to={"/my-account"}
              state={{ id: record._id, activeTab: "profit-loss" }}
              className="w-[26px] h-[26px] ml-[12px] text-[0] flex bg-[#f3f3f3] rounded-[6px] border-[1px] border-[solid] border-[#bbbbbb] float-right cursor-pointer items-center justify-center hover:text-[black]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                width="16px"
                height="16px"
                viewBox="0 0 26 26"
                enableBackground="new 0 0 26 26"
                xmlSpace="preserve"
              >
                <path
                  fill="currentColor"
                  d="M25.958,16.59h-4.393V3.555h-3.287V16.59h-4.391l5.971,5.855L25.958,16.59z"
                ></path>
                <path
                  fill="currentColor"
                  d="M12.08,9.442l-5.97-5.855L0.042,9.442h4.357v13.004h3.324V9.442H12.08z"
                ></path>
              </svg>
            </Link>
          </li>
          <li className="block float-left">
            {/* <Link className='w-[26px] h-[26px] ml-[12px] text-[0] flex bg-[#f3f3f3] rounded-[6px] border-[1px] border-[solid] border-[#bbbbbb] float-right cursor-pointer items-center justify-center'> */}
            <Link
              to={"/my-account"}
              state={{ id: record._id, activeTab: "bet-history" }}
              className="w-[26px] h-[26px] ml-[12px] text-[0] flex bg-[#f3f3f3] rounded-[6px] border-[1px] border-[solid] border-[#bbbbbb] float-right cursor-pointer items-center justify-center hover:text-[black]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                width="16px"
                height="16px"
                viewBox="0 0 26 26"
                enableBackground="new 0 0 26 26"
                xmlSpace="preserve"
              >
                <path
                  fill="currentColor"
                  d="M9.793,15.429v-2.862H0.234v2.862H9.793z"
                ></path>
                <path
                  fill="currentColor"
                  d="M0.234,6.137V9.03h25.501V6.137H0.234z"
                ></path>
                <path
                  fill="currentColor"
                  d="M25.736,0.574H0.234v2.893h25.501V0.574z"
                ></path>
                <path
                  fill="currentColor"
                  d="M11.076,18.062c0,2.028,0.738,3.766,2.15,5.179c1.412,1.449,3.174,2.155,5.197,2.186      c2.054-0.03,3.751-0.736,5.194-2.186c1.443-1.413,2.149-3.15,2.149-5.179c0-2.054-0.706-3.792-2.149-5.236      c-1.443-1.416-3.141-2.123-5.194-2.123c-2.022,0-3.785,0.708-5.197,2.123C11.814,14.27,11.076,16.008,11.076,18.062z"
                ></path>
                <polyline
                  fill="none"
                  stroke="#FFFFFF"
                  strokeWidth="1.2846"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  points="      21.339,20.09 18.453,19.221 18.453,14.463     "
                ></polyline>
              </svg>
            </Link>
          </li>
          <li className="block float-left">
            <button
              className="w-[26px] h-[26px] ml-[12px] text-[0] flex bg-[#f3f3f3] rounded-[6px] border-[1px] border-[solid] border-[#bbbbbb] float-right cursor-pointer items-center justify-center"
              onClick={() => changeStatus(true, record)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                width="16px"
                height="16px"
                viewBox="0 0 26 26"
                enableBackground="new 0 0 26 26"
                xmlSpace="preserve"
              >
                <path
                  fill="currentColor"
                  d="M15.75,0.394H10.25v4.135C9.163,4.891,8.187,5.436,7.318,6.197L3.807,3.985L0.875,8.664l3.511,2.212    c-0.145,0.689-0.217,1.379-0.217,2.14c0,0.799,0.107,1.633,0.29,2.359L1.02,17.659l3.041,4.61l3.474-2.322    c0.833,0.688,1.737,1.158,2.715,1.485v4.175h5.501v-4.209c1.05-0.327,2.028-0.872,2.935-1.635l3.544,2.214l2.896-4.714    l-3.55-2.178c0.184-0.689,0.252-1.343,0.252-2.069c0-0.87-0.104-1.631-0.286-2.358l3.44-2.321l-3.079-4.606l-3.441,2.285    c-0.831-0.653-1.733-1.161-2.711-1.452V0.394z M7.788,13.162c0-0.072,0-0.109,0-0.145c0-1.451,0.508-2.719,1.521-3.736    c1.012-1.017,2.244-1.524,3.69-1.524c1.412,0,2.643,0.507,3.691,1.524c0.979,1.017,1.484,2.176,1.519,3.555    c0,0.036,0,0.072,0,0.145v0.036c0,1.416-0.505,2.648-1.519,3.667c-1.049,1.015-2.279,1.52-3.691,1.52    c-1.446,0-2.679-0.505-3.69-1.52C8.332,15.702,7.826,14.504,7.788,13.162z"
                ></path>
              </svg>
            </button>
          </li>
          <li className="block float-left">
            <Link
              to={"/my-account"}
              state={{ id: record._id, activeTab: "profile" }}
              className="w-[26px] h-[26px] ml-[12px] text-[0] flex bg-[#f3f3f3] rounded-[6px] border-[1px] border-[solid] border-[#bbbbbb] float-right cursor-pointer items-center justify-center hover:text-[black]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                width="16px"
                height="16px"
                viewBox="0 0 26 26"
                enableBackground="new 0 0 26 26"
                xmlSpace="preserve"
              >
                <path
                  fill="currentColor"
                  d="M25.36,21.222c-0.678-0.975-1.612-1.868-2.842-2.634c-2.586-1.699-5.682-2.551-9.37-2.508    c-3.646-0.043-6.783,0.809-9.412,2.508c-1.4,0.894-2.46,1.955-3.095,3.104v3.91H25.36V21.222z"
                ></path>
                <path
                  fill="currentColor"
                  d="M17.176,11.024c1.23-1.233,1.822-2.678,1.822-4.421c0-1.699-0.592-3.188-1.822-4.377    c-1.187-1.232-2.671-1.827-4.367-1.827c-1.738,0-3.18,0.595-4.409,1.827C7.213,3.416,6.576,4.904,6.576,6.603    c0,1.743,0.637,3.188,1.824,4.421c1.229,1.188,2.671,1.827,4.409,1.827C14.505,12.851,15.99,12.212,17.176,11.024z"
                ></path>
              </svg>
            </Link>
          </li>
          <li className="block float-left">
            <button
              className="w-[26px] h-[26px] ml-[12px] text-[0] flex bg-[#f3f3f3] rounded-[6px] border-[1px] border-[solid] border-[#bbbbbb] float-right cursor-pointer items-center justify-center"
              disabled={button}
              onClick={() => deleteUser(record._id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                width="16px"
                height="16px"
                viewBox="0 0 26 26"
                enableBackground="new 0 0 26 26"
                xmlSpace="preserve"
              >
                <path
                  fill="currentColor"
                  d="M16.162,0.213H9.838C9.358,0.535,9.005,1.049,8.78,1.821H2.233c-0.191,0-0.32,0.097-0.32,0.29v2.541    c0,0.193,0.129,0.29,0.32,0.322h21.534c0.224-0.032,0.32-0.129,0.32-0.322V2.111c0-0.193-0.097-0.29-0.32-0.29h-6.514    C16.995,1.049,16.643,0.535,16.162,0.213z"
                ></path>
                <path
                  fill="currentColor"
                  d="M19.725,25.788c1.088-0.453,1.698-1.256,1.795-2.415c0-0.031,0-0.062,0-0.097l1.058-16.694H3.454    l1.027,16.694c0,0.035,0,0.065,0.031,0.097c0.096,1.159,0.674,1.962,1.765,2.415H19.725z"
                ></path>
              </svg>
            </button>
          </li>
        </ul>
      ),
    },
  ];
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handleSearch = (value, dataIndex) => {
    const filtered = data.filter((item) =>
      item[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrent(1); // Reset to the first page after a search
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e);
    setCurrent(1); // Reset to the first page after changing page size
  };

  const handleFirst = () => setCurrent(1);
  const handleLast = () =>
    setCurrent(Math.ceil(filteredData?.length / pageSize));

  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData =
    filteredData?.length > 0 && filteredData.slice(startIndex, endIndex);
  const changeSelectOptionHandler = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    filterBetHistory();
  }, []);

  const filterBetHistory = async (e) => {
    var data = JSON.stringify(formData);
    // console.log('data', data)
    // return false;

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}reports/aurafilterbetHistory`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        try {
          console.log("response.data.resultData", response.data.resultData);
          setFilteredData(response.data.resultData);
        } catch (e) {
          // postErrorToslack(e.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        // postErrorToslack(error.message);
      });
  };

  return (
    <div className="grid grid-cols-12 relative mt-[20px] lg:mt-[15px]   px-[15px] lg:px-[30px]">
      <div className="col-span-12 mb-[1.5rem] lg:mb-[0px]">
        <div className=" flex-[0_0_auto] w-full max-w-full">
          <div className="border-r-[1px] border-r-[#c8ced3] border-l-[1px] border-l-[#c8ced3] mb-[24px] relative flex flex-col min-w-[0] text-[#212529] [word-wrap:break-word] bg-[#fff] bg-clip-border border-[1px] border-[solid] border-[#0000002d] rounded-[.375rem]">
            <div className="bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] font-bold text-[0.938rem] px-[10px] py-[5px] [word-wrap:break-word]">
              <button onClick={handleAddBanner} className="flex items-center gap-[5px] h-[30px] m-0 leading-[30px] px-[15px] py-[0] bg-[#eee] border-[1px] border-[solid] border-[#bbb] rounded-[4px] !text-[#1e1e1e] font-bold text-[.75rem] block float-right no-underline cursor-pointer">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 6C11.3 6 10 7.3 10 9C10 10.7 11.3 12 13 12C14.7 12 16 10.7 16 9C16 7.3 14.7 6 13 6ZM19.5 6C18.1193 6 17 7.11929 17 8.5C17 9.88071 18.1193 11 19.5 11C20.8807 11 22 9.88071 22 8.5C22 7.11929 20.8807 6 19.5 6ZM4 8V11H1V13H4V16H6V13H9V11H6V8H4ZM19.5 13C18.3 13 17.3875 13.3125 16.6875 13.8125C18.9875 14.9125 19.9062 16.8 19.9062 17V17.0938H24V15.8125C24 15.7125 22.9 13 19.5 13ZM13 14C8.4 14 7 17.3125 7 17.3125V19H19V17.3125C19 17.3125 17.6 14 13 14Z"
                    fill="black"
                  />
                </svg>
                Add User
              </button>
              <span className="text-[#ffffff] font-bold text-[0.938rem]  py-[5px] [word-wrap:break-word]">
                Add Banner
              </span>
            </div>
            <div className="p-[1.25rem] flex-auto ">
              <Table
                locale={locale}
                columns={columns}
                className="event-pl"
                bordered
                dataSource={paginatedData}
                pagination={false} // Disable default pagination
              />
              <div className="grid grid-cols-12 items-center pt-[1.5rem] lg:pt-[.75rem] text-[#333]">
                <div className="col-span-12 lg:col-span-6 text-[#333] text-[0.813rem]">
                  Showing {startIndex + 1} to {endIndex} of{" "}
                  {filteredData?.length} entries
                </div>
                <div className="col-span-12 lg:col-span-6 flex items-center justify-end  pt-[1rem] lg:pt-[0px] lg:mt-[0rem]">
                  <button
                    className="text-[0.813rem] cursor-default text-[#666] border-[1px] border-[solid] border-[transparent] bg-transparent [box-shadow:none] box-border inline-block min-w-[1.5em] px-[1em] py-[.5em] ml-[2px] text-center no-underline rounded-[2px] cursor-pointer"
                    type="button"
                    onClick={handleFirst}
                    style={{ marginRight: 8 }}
                  >
                    First
                  </button>
                  <Pagination
                    current={current}
                    pageSize={pageSize}
                    total={filteredData?.length}
                    onChange={(page) => setCurrent(page)}
                    itemRender={(page, type, originalElement) => {
                      if (type === "prev") {
                        return (
                          <button type="button" className="!cursor-pointer">
                            Previous
                          </button>
                        );
                      }
                      if (type === "page") {
                        return null; // Hide page numbers
                      }
                      if (type === "next") {
                        return (
                          <button type="button" className="!cursor-pointer">
                            Next
                          </button>
                        );
                      }
                      return originalElement;
                    }}
                    showSizeChanger={false} // Disable default page size changer
                  />
                  <button
                    className="text-[0.813rem] cursor-default text-[#666] border-[1px] border-[solid] border-[transparent] bg-transparent [box-shadow:none] box-border inline-block min-w-[1.5em] px-[1em] py-[.5em] ml-[2px] text-center no-underline rounded-[2px]  cursor-pointer"
                    type="button"
                    onClick={handleLast}
                    style={{ marginLeft: 8 }}
                  >
                    Last
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <WhatsAppIcon /> */}
    </div>
  );
}

export default HeaderBanner;
